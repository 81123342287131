import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap'
import { BsChatLeftTextFill } from 'react-icons/bs'
import { MdInsertPhoto, MdSegment, } from 'react-icons/md'

export default function Matrix_top() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('');

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);
  return (
    <div className="flex-box-3">
      <div className="Box">
        <h1>ENTER_The_MATRIX</h1>
      </div>
      <div className="flex-box-5">
        <div>
          <p className='d-none'>
            <Link to="/matrix" className={`btn-5 ${activeLink === '/matrix' ? 'active' : ''}`}>
              <BsChatLeftTextFill /> CHAT
            </Link>
          </p>
        </div>
        <div>
          <p>
            <Link to="/text-image" className={`btn-5 ${activeLink === '/text-image' ? 'active' : ''}`}>
              <MdInsertPhoto fontSize="16px" className="me-1" /> IMAGE MATRIX
            </Link>
          </p>
        </div>
        <div>
          <p>
            <Link to="/chat" className={`btn-5 ${activeLink === '/chat' ? 'active' : ''}`}>
              <MdSegment fontSize="16px" className="me-1" /> CHAT MATRIX
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}
