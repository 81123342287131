import React from "react";
import { PieHole } from "../Components/Chart/PieHoleChart";
import { Charts } from "../Components/Chart/Chart";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Barcharts } from "../Components/Chart/Barcharts";

const PROFILE_ADMIN = () => {
  const user = useSelector((state) => state.auth.user);
  if (!user || user?.role != "admin") return <Navigate to="/login" />;
  return (
    <div className="PROFILE_ADMINISTRATOR">
      <div className="Box">
        <h1>PROFILE_ADMINISTRATOR</h1>
      </div>

      <div className="Graph-box">
        <div>
          <div className="flex-box-5">
            <div>
              <p>
                <Button className="btn-5">TODAY</Button>
              </p>
            </div>
            <div>
              <p>
                <Button className="btn-5">THIS WEEK</Button>
              </p>
            </div>
            <div>
              <p>
                <Button className="btn-5">THIS MONTH</Button>
              </p>
            </div>
            <div>
              <p>
                <Button className="btn-5">THIS QUARTER</Button>
              </p>
            </div>
            <div>
              <p>
                <Button className="btn-5">THIS YEAR</Button>
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4">

            <div className="text-box_2">
              <div>
                <p className="md-text">registered users</p>
                <p className="lg-text">5 987,34</p>
                <p className="sm-text">identified this month</p>
              </div>
            </div>
            <div className="Graph">
              <Charts />
              <div className="dot-flex">
                <div className="dot">
                </div>
                <p>Number of registered users</p>
              </div>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="text-box_2">
              <div>
                <p className="md-text">active users</p>
                <p className="lg-text">5 987,34</p>
                <p className="sm-text">logged in at least once this month</p>
              </div>
            </div>
            <div className="Graph">
              <Charts />
              <div className="dot-flex">
                <div className="dot">
                </div>
                <p>Number of active users</p>
              </div>
            </div>
          </div>

          <div className="col-xl-4">
            <div className="text-box_2">
              <div>
                <p className="md-text">RED PILL / BLUE PILL</p>
                <p className="lg-text">5.533</p>
              </div>
            </div>
            <div className="Piehole">
              <PieHole />
              <div className="d-flex align-items-center">
                <div className="dot-flex">
                  <div className="dot" style={{background:"rgb(220, 57, 18)"}}>
                  </div>
                  <p>Paid subscriptions</p>
                </div>

                <div className="dot-flex">
                  <div className="dot"  style={{background:"rgb(51, 102, 204)"}}>
                  </div>
                  <p>Free subscriptions</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Graph-box mt-5">
        <div className="row">
          <div className="col-xl-6">

            <div className="text-box_2">
              <div>
                <p className="md-text">Amount of PAID subscriptions</p>
                <p className="lg-text">5 987,34</p>
                <p className="sm-text">amount this month</p>
              </div>
            </div>
            <div className="Graph Bar-graph">
              <Barcharts />
              <div className="d-flex align-items-center">
                <div className="dot-flex">
                  <div className="dot">
                  </div>
                  <p>Revenue earned</p>
                </div>

                <div className="dot-flex">
                  <div className="dot"  style={{background:"#FF0000"}}>
                  </div>
                  <p>Revenues reimbursed</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="text-box_2">
              <div>
                <p className="md-text">number of paid subscriptions</p>
                <p className="lg-text">5 987,34</p>
                <p className="sm-text">Paid subscriptions this month</p>
              </div>
            </div>
            <div className="Graph">
              <Charts />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PROFILE_ADMIN;
