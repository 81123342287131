import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

const Acordianbox = () => {
    return (
        <div className='Accordian' style={{ maxWidth: '820px', margin: 'auto', paddingTop: "100px" }}>
            <div className='Heading'>
                <h1>Frequently Asked Questions</h1>
            </div>

            <div>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>What makes Morpheus.Ai different?</Accordion.Header>
                        <Accordion.Body>
                            Morpheus is an AI-based content production suite that offers the curious as well as
                            creators a powerful, customizable and user-friendly platform that centralizes many AI
                            to bring their wildest imaginations to life.
                            <br />
                            <br />
                            By emphasizing the plurality of elements related to the creation of content via AI, we
                            place the creators as well as the curious at the center of a transcendental process of
                            creation allowing them to overcome their mental barriers, the impossible becomes
                            possible, the unimaginable imaginable, the implementable, the only limit that exists is
                            the one they impose on themselves.

                            <br />
                            <br />
                            Our custom back-end offers advances in fine-tuning the model, quick adhesion,
                            training speed and inference, as well as multi-frame prompt functionality. We’re also
                            solving common image degradation issues and have implemented custom scaling,
                            and much more is on the way!
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>How do models work?</Accordion.Header>
                        <Accordion.Body>
                            At Morpheus.ai, we emphasize versatility. Our platform is not confined to a single
                            generalized model; instead, we offer a diverse range of both general and specialized
                            models, each designed to excel in various types of content generation. This diversity
                            allows users to go beyond a one-size-fits-all approach and choose the model that
                            best fits their specific image type or use case.
                            <br />
                            <br />
                            These models are not limited to any particular field. Whether it's art, design, gaming,
                            or beyond, our models can accommodate virtually any creative endeavor.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>What counts as a generation?</Accordion.Header>
                        <Accordion.Body>
                            We currently offer two modes of generations : one for images and another one for
                            text (chat), soon we will add motion and audio.
                            <br />
                            <br />
                            Any generated image or chat exchange is counted as generation.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Will I own the images I make?</Accordion.Header>
                        <Accordion.Body>
                            Your image ownership rights vary based on your Morpheus.ai subscription status:
                            <br />
                            <br />
                            <span className='fw-semibold text-light' style={{fontSize:"16px",letterSpacing:"1px"}}>Paid subscribers : </span> Yes, you retain full ownership, copyright, and other intellectual property rights of the images you generate. Morpheus.ai may use your private
                            images only to provide you with our services. Public images can be used by
                            Morpheus.ai and other users, only as enabled by a feature of the service.
                            <br />
                            <br />
                            <span className='fw-semibold text-light' style={{fontSize:"16px",letterSpacing:"1px"}}>Free tier users : </span> Morpheus.ai holds the rights to use, reproduce, modify, and
                            distribute any images you create. However, you're granted a non-exclusive,
                            royalty-free license to access your creations for commercial use. Other users can
                            also access your publicly available assets and use them only as enabled by a
                            feature of the service.
                            <br />
                            <br />
                            For more detailed information, please refer to our Terms of Service.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>How do tokens work and how often do they replenish?</Accordion.Header>
                        <Accordion.Body>
                            Tokens serve as your 'currency' on the Morpheus.ai platform, deducted from your
                            account balance as you perform various actions—such as generating images,
                            creating videos, post-processing tasks like background removal, and upscale
                            conversions.
                            <br />
                            <br />
                            The number of tokens used is based on the computational intensity of the action
                            you're performing — the greater the GPU load required, the more tokens are
                            charged. It's important to note that a single token doesn't correspond to a specific
                            image size or action. The token cost adjusts according to the complexity and
                            computational requirements of the task you're executing.
                            <br />
                            <br />
                            If you're a free user, you're allocated a certain number of tokens each day, which
                            reset daily, allowing you to perform actions within this limit.
                            <br />
                            <br />
                            As a paid subscriber, you receive a monthly token allowance that provides greater
                            flexibility to use Morpheus.ai's premium features at your own pace. These tokens
                            reset and replenish at the beginning of each billing month, ensuring you always start
                            with a fresh balance.

                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>Can I use my generated images for commercial projects?</Accordion.Header>
                        <Accordion.Body>
                            Yes, you can. However, the rights to use generated images in commercial projects
                            depend on your subscription status:
                            <br />
                            <br />
                            <span className='fw-semibold text-light' style={{fontSize:"16px",letterSpacing:"1px"}}>Paid subscribers : </span> You retain full ownership and intellectual property rights of your generated images, allowing you to use these creations for commercial projects.
                            <br />
                            <br />
                            <span className='fw-semibold text-light' style={{fontSize:"16px",letterSpacing:"1px"}}>Free tier users : </span> While Morpheus.ai holds the rights to the images you create, you are granted a non-exclusive, royalty-free license to use your generated content for commercial purposes.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>What happens if I run out of tokens in my paid plan?</Accordion.Header>
                        <Accordion.Body>
                            What happens when you run out of tokens depends on your subscription tier :
                            <br />
                            <br />
                            <span className='fw-semibold text-light' style={{fontSize:"16px",letterSpacing:"1px"}}>Freed or Erudite : </span>  After depleting your tokens, you will receive free tokens and you will even have access to the 'relaxed queue' and can continue creating at a slower
                            pace. However, the speed of your jobs will depend on the busyness of the priority queue.

                            <br />
                            <br />
                            <span className='fw-semibold text-light' style={{fontSize:"16px",letterSpacing:"1px"}}>Awake : </span> Once you run out of tokens, you can either wait until your tokens reset on
                            your next billing date or consider upgrading to a higher plan for more tokens and
                            additional features.

                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>Can I change my plan later?</Accordion.Header>
                        <Accordion.Body>
                        You will soon have the option to upgrade or downgrade your plan.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header>Does the pricing include tax?</Accordion.Header>
                        <Accordion.Body>
                        Pricing in this table excludes tax. Applicable taxes will be added at the point of payment with Mollie. If we do not currently charge GST or VAT in your country or region but need to start doing so in the future after meeting a local threshold, these taxes will be added to your recurring bill at that time
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    )
}

export default Acordianbox
