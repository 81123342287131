import React from "react";
import Prizingtop from "./prizingtop";
import Button from "react-bootstrap/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Acordianbox from "./Acordianbox";
import { createSubscription } from "react-redux/es/utils/Subscription";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const Annuel = () => {
  const handlePayment = async (subscriptionPlan) => {
    console.log("🚀 ~ handlePayment ~ subscriptionPlan:", subscriptionPlan);
    const basePath = process.env.REACT_APP_basePath;
    try {
      const response = await axios.post(
      basePath + "/subscription",
        
        { subscriptionPlan },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const { paymentUrl } = response.data; // Access data directly

      console.log("🚀 ~ handlePayment ~ paymentUrl:", paymentUrl);
      window.location.href = paymentUrl;
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  //   let userlocal = useSelector((state) => state.auth?.user);
  let userlocal = useSelector((state) => state.auth?.user);
  console.log("🚀 ~ Annuel>>>>>>>>> ~ userlocal:", userlocal);

  return (
    <div className="Annuel">
      <div>
        {/* <button onClick={()=>handlePayment("Free")}>Pay with Mollie</button> */}

        <Prizingtop />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="card">
              <div className="header-card">
                <div>
                  <h5>FREE</h5>
                </div>
                <div className="payment-box">
                  <h4>
                    <span className="h1">0$</span>/MONTH
                  </h4>
                </div>

                <div
                  className="d-flex"
                  style={{
                    cursor: "not-allowed",
                    display: "block",
                    opacity: 0.5,
                    pointerEvents: "none",
                  }}
                >
                  <Button>Forever</Button>
                </div>
              </div>
              <div className="token-box">
                <hr />
                <p>150 TOKENS PAR MONTH</p>
                <hr />
              </div>
              <div className="content-box">
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">Generate up to 9 images per month</p>
                </div>
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">
                    Make up to 75 exchanges (chat) per month
                  </p>
                </div>
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">
                    Free tokens when the balance is less than 150
                  </p>
                </div>
              </div>
              <div className="content-box-2">
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">Up to 1 pending jobs</p>
                </div>
                <div className="flex-box-11">
                  <p>
                    <CloseIcon />
                  </p>
                  <p className="ms-2">Private generations</p>
                </div>
                <div className="flex-box-11">
                  <p>
                    <CloseIcon />
                  </p>
                  <p className="ms-2">Priority infrastructure</p>
                </div>
                <div className="flex-box-11">
                  <p>
                    <CloseIcon />
                  </p>
                  <p className="ms-2">Express queue</p>
                </div>
                <div className="flex-box-11">
                  <p>
                    <CloseIcon />
                  </p>
                  <p className="ms-2">Retroactivity of tokens per month</p>
                </div>
              </div>

              <div className="card-2">
                <div className="content-box">
                  <h6>AI models available</h6>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">GPT-4</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Llama</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">DALL·E 3</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">SDXL 0.9</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">SDXL 1.0</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">StableDiffusion 1.5</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">StableDiffusion 2.1</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">DreamShaper v7</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">AbsoluteReality v1.6</p>
                  </div>
                </div>
                <div className="content-box">
                  <h6 className="pt-5">Generation & Uses</h6>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Chat prompt</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Image-2-Image</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Image Prompts</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Prompt Generation</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Tiling</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Commercial use</p>
                  </div>
                  <div className="flex-box-11">
                    <p>
                      <CloseIcon />
                    </p>
                    <p className="ms-2">Private generations</p>
                  </div>
                  <div className="flex-box-11">
                    <p>
                      <CloseIcon />
                    </p>
                    <p className="ms-2">Express queue</p>
                  </div>
                  <div className="flex-box-11">
                    <p>
                      <CloseIcon />
                    </p>
                    <p className="ms-2">Retroactivity of tokens per month</p>
                  </div>
                </div>
              </div>

              <div className="token-box">
                <hr />
                <p>SPECIAL BENEFITS</p>
                <hr />
              </div>
              <div className="flex-box-11">
                <p>
                  <CloseIcon />
                </p>
                <p className="ms-2">
                  Early access to new features
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="card">
              <div className="header-card">
                <div>
                  <h5>awake</h5>
                </div>
                <div className="payment-box">
                  <h4>
                    <span className="h1">11.99$</span>/MONTH
                  </h4>
                </div>
                <div
                  className="d-flex"
                  style={
                    userlocal?.subscriptionPlan == "Awake" &&
                    userlocal?.total_points_earned > 2
                      ? {
                          cursor: "not-allowed",
                          display: "block",
                          opacity: 0.5,
                          pointerEvents: "none",
                        }
                      : {}
                  }
                  onClick={() => handlePayment("Awake")}
                >
                  <Button>Subscribe</Button>
                </div>
              </div>
              <div className="token-box">
                <hr />
                <p>8500 TOKENS PER MONTH</p>
                <hr />
              </div>
              <div className="content-box">
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">Generate up to 531 images per month</p>
                </div>
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">
                    Make up to 4250 exchanges (chat) per month
                  </p>
                </div>
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">
                    Free tokens when the balance is less than 150
                  </p>
                </div>
              </div>
              <div className="content-box-2">
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">Up to 5 pending jobs</p>
                </div>
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">Private generations</p>
                </div>
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">Priority infrastructure</p>
                </div>
                <div className="flex-box-11">
                  <p>
                    <CloseIcon />
                  </p>
                  <p className="ms-2">Express queue</p>
                </div>
                <div className="flex-box-11">
                  <p>
                    <CloseIcon />
                  </p>
                  <p className="ms-2">Retroactivity of tokens per month</p>
                </div>
              </div>

              <div className="card-2">
                <div className="content-box">
                  <h6>AI models available</h6>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">GPT-4</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Llama</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">DALL·E 3</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">SDXL 0.9</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">SDXL 1.0</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">StableDiffusion 1.5</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">StableDiffusion 2.1</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">DreamShaper v7</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">AbsoluteReality v1.6</p>
                  </div>
                </div>
                <div className="content-box">
                  <h6 className="pt-5">Generation & Uses</h6>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Chat prompt</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Image-2-Image</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Image Prompts</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Prompt Generation</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Tiling</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Commercial use</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Private generations</p>
                  </div>
                  <div className="flex-box-11">
                    <p>
                      <CloseIcon />
                    </p>
                    <p className="ms-2">Express queue</p>
                  </div>
                  <div className="flex-box-11">
                    <p>
                      <CloseIcon />
                    </p>
                    <p className="ms-2">Retroactivity of tokens per month</p>
                  </div>
                </div>
              </div>

              <div className="token-box">
                <hr />
                <p>SPECIAL BENEFITS</p>
                <hr />
              </div>
              <div className="flex-box-9">
                <p>
                  <CheckIcon />
                </p>
                <p className="ms-2">
                  Early access to new features
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="card">
              <div className="header-card">
                <div>
                  <h5>freed</h5>
                </div>
                <div className="payment-box">
                  <h4>
                    <span className="h1">29.99$</span>/MONTH
                  </h4>
                </div>
                <div
                  className="d-flex"
                  style={
                    userlocal?.subscriptionPlan == "Freed" &&
                    userlocal?.total_points_earned > 2
                      ? {
                          cursor: "not-allowed",
                          display: "block",
                          opacity: 0.5,
                          pointerEvents: "none",
                        }
                      : {}
                  }
                  onClick={() => handlePayment("Freed")}
                >
                  <Button>Subscribe</Button>
                </div>
              </div>
              <div className="token-box">
                <hr />
                <p>25000 TOKENS PER MONTH</p>
                <hr />
              </div>
              <div className="content-box">
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">Generate up to 1562 images per month</p>
                </div>
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">
                    Make up to 12500 exchanges (chat) per month
                  </p>
                </div>
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">
                    Free tokens when the balance is less than 150
                  </p>
                </div>
              </div>
              <div className="content-box-2">
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">Up to 10 pending jobs</p>
                </div>
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">Private generations</p>
                </div>
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">Priority infrastructure</p>
                </div>
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">Express queue</p>
                </div>
                <div className="flex-box-11">
                  <p>
                    <CloseIcon />
                  </p>
                  <p className="ms-2">Retroactivity of tokens per month</p>
                </div>
              </div>

              <div className="card-2">
                <div className="content-box">
                  <h6>AI models available</h6>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">GPT-4</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Llama</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">DALL·E 3</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">SDXL 0.9</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">SDXL 1.0</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">StableDiffusion 1.5</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">StableDiffusion 2.1</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">DreamShaper v7</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">AbsoluteReality v1.6</p>
                  </div>
                </div>
                <div className="content-box">
                  <h6 className="pt-5">Generation & Uses</h6>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Chat prompt</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Image-2-Image</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Image Prompts</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Prompt Generation</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Tiling</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Commercial use</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Private generations</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Express queue</p>
                  </div>
                  <div className="flex-box-11">
                    <p>
                      <CloseIcon />
                    </p>
                    <p className="ms-2">Retroactivity of tokens per month</p>
                  </div>
                </div>
              </div>

              <div className="token-box">
                <hr />
                <p>SPECIAL BENEFITS</p>
                <hr />
              </div>
              <div className="flex-box-9">
                <p>
                  <CheckIcon />
                </p>
                <p className="ms-2">
                  Early access to new features
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <div className="card">
              <div className="header-card">
                <div>
                  <h5>erudite</h5>
                </div>
                <div className="payment-box">
                  <h4>
                    <span className="h1">59.99$</span>/MONTH
                  </h4>
                </div>
                <div
                  className="d-flex"
                  style={
                    userlocal?.subscriptionPlan == "Erudite" &&
                    userlocal?.total_points_earned > 2
                      ? {
                          cursor: "not-allowed",
                          display: "block",
                          opacity: 0.5,
                          pointerEvents: "none",
                        }
                      : {}
                  }
                  onClick={() => handlePayment("Erudite")}
                >
                  <Button>Subscribe</Button>
                </div>
              </div>
              <div className="token-box">
                <hr />
                <p>60000 TOKENS PER MONTH</p>
                <hr />
              </div>
              <div className="content-box">
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">Generate up to 3750 images per month</p>
                </div>
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">
                    Make up to 30000 exchanges (chat) per month
                  </p>
                </div>
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">
                    Free tokens when the balance is less than 150
                  </p>
                </div>
              </div>
              <div className="content-box-2">
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">Up to 20 pending jobs</p>
                </div>
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">Private generations</p>
                </div>
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">Priority infrastructure</p>
                </div>
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">Express queue</p>
                </div>
                <div className="flex-box-9">
                  <p>
                    <CheckIcon />
                  </p>
                  <p className="ms-2">Retroactivity of tokens per month</p>
                </div>
              </div>

              <div className="card-2">
                <div className="content-box">
                  <h6>AI models available</h6>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">GPT-4</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Llama</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">DALL·E 3</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">SDXL 0.9</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">SDXL 1.0</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">StableDiffusion 1.5</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">StableDiffusion 2.1</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">DreamShaper v7</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">AbsoluteReality v1.6</p>
                  </div>
                </div>
                <div className="content-box">
                  <h6 className="pt-5">Generation & Uses</h6>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Chat prompt</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Image-2-Image</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Image Prompts</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Prompt Generation</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Tiling</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Commercial use</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Private generations</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Express queue</p>
                  </div>
                  <div className="flex-box-9">
                    <p>
                      <CheckIcon />
                    </p>
                    <p className="ms-2">Retroactivity of tokens per month</p>
                  </div>
                </div>
              </div>

              <div className="token-box">
                <hr />
                <p>SPECIAL BENEFITS</p>
                <hr />
              </div>
              <div className="flex-box-9">
                <p>
                  <CheckIcon />
                </p>
                <p className="ms-2">
                  Early access to new features
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Acordianbox />
        </div>
      </div>
    </div>
  );
};

export default Annuel;
