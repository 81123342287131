import React from 'react'

const Coins = () => {
  return (
    <div className='Coins_Section'>
      <div>
        
      </div>
      
    </div>
  )
}

export default Coins
