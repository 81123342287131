import React from 'react';
import KeyboardBackspaceTwoToneIcon from "@mui/icons-material/KeyboardBackspaceTwoTone";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const UTILISATION = () => {
  const { user } = useSelector((s) => s.auth);

  return (
    <div className='noticeLegal'>
      {
        Object.keys(user).length === 0 && <div className="Back_box d-lg-flex d-none">
          <div className="icon-box">
            <Link to="/">
              <KeyboardBackspaceTwoToneIcon />
            </Link>
          </div>
          <div className="icon-box">
            <Link to="/">
              <p>Retour</p>
            </Link>
          </div>
        </div>
      }
      <div className="noticelegal_content">
        <div className="Back_box">
          <div className="icon-box">
            <Link to="/">
              {" "}
              <KeyboardBackspaceTwoToneIcon />
            </Link>
          </div>
          <div className="icon-box">
            <Link to="/">
              <p>Back to Morpheus</p>
            </Link>
          </div>
        </div>
        <div className="notice_para">
          <h2 className="f-para">Term_of_Uses</h2>
          <span className="para_content">
            <h4 className="para_heading_4">1. Introduction</h4>
            Welcome to the digital realm of Morpheus.ai. These terms are the code that governs
            your journey through our world. By entering and engaging with our services, you
            acknowledge and accept this reality.
            <br />
            <br />
            <h4 className="para_heading_4">2. Services Offered</h4>
            In this universe, Morpheus.ai grants you access to two powerful tools:
            <span>
              <li>
                Chatbot (GPT, LLAMA, Gemini)
              </li>
              <li>
                Content Generation (Dalle-3, Stable Diffusion 3, SDXL 1.0, and beyond)
              </li>
            </span>
            Soon, the fabric of this reality will expand to include video and audio generation.
            <br />
            <br />
            <h4 className="para_heading_4">3. Target Users</h4>
            Our services are designed for those who seek truth: individuals, content creators,
            developers, and businesses.
            <br />
            <br />
            <h4 className="para_heading_4">4. Data Collection</h4>
            We extract only what is necessary from the matrix:
            <span>
              <li>
                Your email address
              </li>
              <li>
                Your payment information
              </li>
            </span>
            <br />
            <br />
            <h4 className="para_heading_4">5. Use of Data</h4>
            Your data serves a higher purpose:
            <span>
              <li>
                Email Addresses: For communication and improvement, enhancing your
                journey through targeted marketing and surveys.
              </li>
              <li>
                Payment Information: Shared with Mollie, the gatekeeper of transactions.
              </li>
            </span>
            <br />
            <br />
            <h4 className="para_heading_4">6. Data Security</h4>
            Your data is safeguarded under the protocols of the General Data Protection
            Regulation (GDPR).
            <br />
            <br />
            <h4 className="para_heading_4">7. Use of Services</h4>
            These services must not be used for malevolent purposes or to harm others within
            the matrix.
            <br />
            <br />
            <h4 className="para_heading_4">8. Intellectual Property</h4>
            The creations you bring forth into this world are yours to wield, for any legal purpose,
            including commercial ventures.
            <br />
            <br />
            <h4 className="para_heading_4">
              9. Liability
            </h4>
            Morpheus.ai stands responsible, except in realms beyond our control: connection
            quality, depletion of tokens, and the inherent quality of generated images.
            <br />
            <br />
            <h4 className="para_heading_4">10. Pricing Model</h4>
            A dual path exists:
            <span>
              <li>
                Free Use: Access the basics, journey at your own pace.
              </li>
              <li>
                Subscription: Unlock advanced features, for those ready to delve deeper.
              </li>
            </span>
            To sever your subscription, send a message to contact@themorpheus.ai, detailing
            your intent and reasons.
            <br />
            <br />
            <h4 className="para_heading_4">11. Free Trial</h4>
            Every new initiate begins with a free plan, experiencing the range of our capabilities.
            The sole requirement is the creation of an account.
            <br />
            <br />
            <h4 className="para_heading_4">12. Legal Compliance</h4>
            We align with the laws of the digital world, respecting GDPR.
            <br />
            <br />
            <h4 className="para_heading_4">13. Minimum Age</h4>
            There are no age restrictions to embark on this journey.
            <br />
            <br />
            <h4 className="para_heading_4">14. User Support</h4>
            Our guides are available to assist you, seven days a week.
            <br />
            <br />
            <h4 className="para_heading_4">15. Changes to Terms</h4>
            The code of this world is subject to change. You will be informed of any alterations
            via email or through our platform.
            <br />
            <br />
            <h4 className="para_heading_4">16. Contact</h4>
            For inquiries or guidance regarding these terms, reach out to us at
            contact@themorpheus.ai.
          </span>

        </div>
      </div>

    </div>
  )
}

export default UTILISATION
