import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useNavigate, useSearchParams } from "react-router-dom";

const Slide = () => {
  const router = useNavigate();
  const styles = [
    { name: "DALLE 3", value: "dalle-3", text: "DALL·E 3 understands significantly many nuance and detail, allowing you to easily translate your ideas into exceptionally accurate images." },
    { name: "Stable Diffusion 3", value: "stable-diffusion-3", text: "Stable Diffusion 3 is an advanced image model , features the latest in text-to-image technology with a huge performance in multi-subject prompts, image quality, and spelling abilities." },
    { name: "SDXL 1.0", value: "SDXL-1.0", text: "SDXL is a model of Stable Diffusion, which  allows to generate quality images without long prompts." },
    { name: "DreamShaper v7", value: "DreamShaper v7", text: "DreamShaper is an alternative to MidJourney in the open source world.It is currently better than Midjourney because it gives greater freedom to the user" },
    { name: "AbsoluteReality v1.6", value: "comic-book", text: "Abosulte Reality is a model for art, it allows to generate images of breathtaking quality and also images that are close to the real." },

    { name: "DALLE 3", value: "dalle-3", text: "DALL·E 3 understands significantly many nuance and detail, allowing you to easily translate your ideas into exceptionally accurate images." },
    { name: "Stable Diffusion 3", value: "stable-diffusion-3", text: "Stable Diffusion 3 is an advanced image model , features the latest in text-to-image technology with a huge performance in multi-subject prompts, image quality, and spelling abilities." },
    { name: "SDXL 1.0", value: "SDXL-1.0", text: "SDXL is a model of Stable Diffusion, which  allows to generate quality images without long prompts." },
    { name: "DreamShaper v7", value: "DreamShaper v7", text: "DreamShaper is an alternative to MidJourney in the open source world.It is currently better than Midjourney because it gives greater freedom to the user" },
    { name: "AbsoluteReality v1.6", value: "comic-book", text: "Abosulte Reality is a model for art, it allows to generate images of breathtaking quality and also images that are close to the real." },
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 2000, min: 1200 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1199, min: 992 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 500 },
      items: 2,
    },
  };
  return (
    <div>
      <div>
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          showDots={false}
          autoPlaySpeed={8000}
        >
          {styles.map((style, i) => {
            return (
              <div className="h-100">
                <Card>
                  <div className="cards-img">
                    <Card.Img variant="top" src={`/Images/Carousel/Top${i + 1}.png`} />
                  </div>
                  <Card.Body>
                    <Card.Title>{style.name}</Card.Title>
                    <Card.Text>
                      {style.text}
                    </Card.Text>
                    {/* <Button
                      className="carasol_btn"
                      variant="primary"
                      onClick={() => {
                        router(
                          `/text-image?style=${style.value}&model=stable-diffusion-xl-1024-v1-0`
                        );
                      }}
                    >
                      Télécharger le modèle
                    </Button> */}
                  </Card.Body>
                </Card>
              </div>
            );
          })}

          {/* <div>
            <Card>
              <Card.Img variant="top" src={"/Images/Top2.png"} />
              <Card.Body>
                <Card.Title>Digital Art</Card.Title>
                <Card.Text>
                  Lorem ipsum dolor sit amet consectetur. Non elementum enim
                  sagittis pellentesque semper. Ipsum mattis ornare ullamcorper
                  id volutpat nulla. Est ullamcorper ullamcorper libero lorem
                  nunc consectetur platea mauris. Integer commodo laoreet
                  viverra lacus mi.
                </Card.Text>
                <Button
                  className="carasol_btn"
                  variant="primary"
                  onClick={() => {
                    router(
                      "/text-image?style=digital-art&model=stable-diffusion-xl-1024-v0-9"
                    );
                  }}
                >
                  Télécharger le modèle
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div>
            <Card>
              <Card.Img variant="top" src={"/Images/Top3.png"} />
              <Card.Body>
                <Card.Title>Comic Book</Card.Title>
                <Card.Text>
                  Lorem ipsum dolor sit amet consectetur. Non elementum enim
                  sagittis pellentesque semper. Ipsum mattis ornare ullamcorper
                  id volutpat nulla. Est ullamcorper ullamcorper libero lorem
                  nunc consectetur platea mauris. Integer commodo laoreet
                  viverra lacus mi.
                </Card.Text>
                <Button
                  className="carasol_btn"
                  variant="primary"
                  onClick={() => {
                    router(
                      "/text-image?style=comic-book&model=stable-diffusion-v1-5"
                    );
                  }}
                >
                  Télécharger le modèle
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div>
            <Card>
              <Card.Img variant="top" src={"/Images/Top4.png"} />
              <Card.Body>
                <Card.Title>Fantasy Art</Card.Title>
                <Card.Text>
                  Lorem ipsum dolor sit amet consectetur. Non elementum enim
                  sagittis pellentesque semper. Ipsum mattis ornare ullamcorper
                  id volutpat nulla. Est ullamcorper ullamcorper libero lorem
                  nunc consectetur platea mauris. Integer commodo laoreet
                  viverra lacus mi.
                </Card.Text>
                <Button
                  className="carasol_btn"
                  variant="primary"
                  onClick={() => {
                    router(
                      "/text-image?style=fantasy-art&model=stable-diffusion-v1-5"
                    );
                  }}
                >
                  Télécharger le modèle
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div>
            <Card>
              <Card.Img variant="top" src={"/Images/Top5.png"} />
              <Card.Body>
                <Card.Title>Line Art</Card.Title>
                <Card.Text>
                  Lorem ipsum dolor sit amet consectetur. Non elementum enim
                  sagittis pellentesque semper. Ipsum mattis ornare ullamcorper
                  id volutpat nulla. Est ullamcorper ullamcorper libero lorem
                  nunc.
                </Card.Text>
                <Button
                  className="carasol_btn"
                  variant="primary"
                  onClick={() => {
                    router(
                      "/text-image?style=line-art&model=stable-diffusion-512-v2-1"
                    );
                  }}
                >
                  Télécharger le modèle
                </Button>
              </Card.Body>
            </Card>
          </div> */}
        </Carousel>
      </div>
    </div>
  );
};

export default Slide;
