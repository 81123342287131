import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, InputGroup, Form, Spinner } from "react-bootstrap";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { FaLocationArrow } from "react-icons/fa6";
import { FaCoins } from "react-icons/fa";
import Matrix_top from "../Components/Matrix_top";
import {
  createChatAsyncThunk,
  createGeminiChatAsyncThunk,
  getSingleChatAsyncThunk,
  setLoading,
  updateChatAsyncThunk,
} from "../store/pagesSlices/chatsSlice";
import { CSSTransition, TransitionGroup } from "react-transition-group";
// import Matrixbtn from "./Matrixbtn";
import { FaPlus } from "react-icons/fa";

const Matrixchat = () => {
  const user = useSelector((state) => state?.auth?.user);
  const prfileImg = `${process.env.REACT_APP_basePath}${user?.avatar}`;
  const [content, setContent] = useState("");
  const [model, setModel] = useState("gpt3");
  const [showButtons, setShowButtons] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const chat = useSelector((state) => state.chat.chat);
  const loading = useSelector((state) => state.chat.isLoading);
  const navigate = useNavigate();
  const listRef = useRef();

  useEffect(() => {
    listRef.current?.lastElementChild?.scrollIntoView();
  }, [chat?.messages]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!loading) {
      dispatch(setLoading(true));
      if (chat && chat?.id) {
        dispatch(
          updateChatAsyncThunk({ data: { content, model }, id: chat?.id })
        );
        setContent("");
        dispatch(setLoading(false));
        // } else if (model === "gemini-pro") {
        //   console.log("call gemini");
        //   dispatch(
        //     createGeminiChatAsyncThunk({
        //       content,
        //       model,
        //       cb: (res) => {
        //         console.log("res>>>>>>chat>>>>>", res);
        //         navigate("/chat/" + res?.chat?.id);
        //       },
        //     })
        //   );
        //   setContent("");
        //   dispatch(setLoading(false));
      } else {
        dispatch(
          createChatAsyncThunk({
            content,
            model,
            cb: (res) => {
              console.log("res>>>>>>chat>>>>>", res);
              navigate("/chat/" + res?.chat?.id);
            },
          })
        );
        setContent("");
        dispatch(setLoading(false));
      }
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getSingleChatAsyncThunk(id));
    }
  }, [id]);

  let userlocal = useSelector((state) => state.auth?.user);
  console.log("🚀 ~ 12312321 ~ userlocal:", userlocal);

  const [selectedButton, setSelectedButton] = useState("");

  const handleMainButtonClick = () => {
    setShowButtons(prevShowButtons => !prevShowButtons);
  };

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
    setShowButtons(false); 
  };

  useEffect(() => {
    const toggle = document.querySelector(".toggle");
    const menu = document.querySelector(".menu");

    const handleClick = () => {
      menu.classList.toggle("active");
    };

    toggle.addEventListener("click", handleClick);

    return () => {
      toggle.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div className="Enter_The_MATRIX">
      <div>
        <Matrix_top />
      </div>
      <div className="fixed">
        <div className="chat_section">
          <div className="scroll-down" ref={listRef}>
            {chat && chat?.messages && id
              ? chat?.messages?.map((message) => (
                <>
                  {message?.role === "user" ? (
                    <div className="user_text">
                      <img src={prfileImg} className=""></img>
                      <div className="user_textp">
                        <p>{message?.content}</p>
                      </div>
                    </div>
                  ) : (
                    <div className="AI_text">
                      <img src={"/Images/logo.png"} className=""></img>
                      <div className="user_textp">
                        <p>{message?.content}</p>
                      </div>
                    </div>
                  )}
                </>
              ))
              : null}
          </div>
        </div>

        <div className="Position-box-2">
          <div className="Button-box">
            <div className="Border-box">
              <div>
                <div className="sm-text">Response style</div>
                <div className="lg-text">Serious</div>
              </div>
            </div>
            <div className="Border-box">
              <div className="flex-box-9">
                <div>
                  <div className="sm-text">Your plan</div>
                  <div className="lg-text">Free</div>
                </div>
                <div>
                  <Link to="/annuel">
                    <button>Upgrade</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="Border-box">
              <div>
                <div className="sm-text">Advanced mode</div>
                <Form.Select
                  size="sm"
                  onChange={(e) => setModel(e.target.value)}
                >
                  <option value={"gpt3"}>GPT</option>
                  <option value={"lama"}>LLAMA</option>
                  <option value={"gemini-pro"}>Gemini</option>
                </Form.Select>
              </div>
            </div>
          </div>
          <Form onSubmit={onSubmit}>
            <div className="Form_box row">
              <div className="col-sm-11">
                <InputGroup className="">
                  <InputGroup.Text id="basic-addon1">
                    <AutoAwesomeIcon fontSize="10px" />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Type a prompt ..."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="BUTTONS col-sm-1">
                <div className="button_box">
                  {loading ? (
                    <Spinner />
                  ) : (
                    <Button className="btn-7" onClick={onSubmit}>
                      <span className="d-sm-flex d-none">
                        <FaLocationArrow />
                      </span>
                      <span className="d-sm-none d-flex">GENERATE IMAGE</span>
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="Btns justify-content-center">
              {!showButtons && (
                <Button
                  onClick={handleMainButtonClick}
                  className="Btn-2 position-static"
                  style={{ transform: "translateX(0%)" }}
                >
                  {selectedButton ? selectedButton : "Select"}
                </Button>
              )}
              <TransitionGroup>
                {showButtons && (
                  <CSSTransition classNames="btns" timeout={300}>
                    <div className="Btns d-sm-flex d-none">
                      <Button
                        className="Btn-2"
                        onClick={() => handleButtonClick("BARD")}
                      >
                        BARD
                      </Button>
                      <Button
                        className="Btn-2"
                        onClick={() => handleButtonClick("GPT")}
                      >
                        GPT
                      </Button>
                      <Button
                        className="Btn-2"
                        onClick={() => handleButtonClick("LLAMA")}
                      >
                        LLAMA
                      </Button>
                      <Button
                        className="Btn-2"
                        onClick={() => handleButtonClick("GEMINI")}
                      >
                        Gemini
                      </Button>
                    </div>
                  </CSSTransition>
                )}
              </TransitionGroup>
            </div> */}
            {/* <Matrixbtn/> */}
            <div className="Animation-box">
      <ul className={`menu ${showButtons ? 'active' : ''}`}>
        <div className="toggle" onClick={handleMainButtonClick}>
          <p className="position-static m-0 d-flex" style={{ transform: "translateX(0%)" }}>
            {selectedButton ? selectedButton : <FaPlus />}
          </p>
        </div>
        <li style={{ "--i": 1 }} onClick={() => handleButtonClick("GPT")}>
          <button className="Btn-2">GPT</button>
        </li>
        <li style={{ "--i": 2 }} onClick={() => handleButtonClick("GEMINI")}>
          <button className="Btn-2">GEMINI</button>
        </li>
        <li style={{ "--i": 3 }} onClick={() => handleButtonClick("LLAMA")}>
          <button className="Btn-2">LLAMA</button>
        </li>
      </ul>
    </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Matrixchat;
