import React from 'react';
import { Chart } from "react-google-charts";

export const data = [
    ["", "", ""],
    ["Jan", 7.5, 9],
    ["Feb", -7.1, -6.9],
    ["Mar", 6.5, 10],
    ["Apr", 7, 9.1],
    ["May", 2, 0.1],

];

export const options = {
    chart: {
    },
};



export function Barcharts() {
    return (
        <Chart
            chartType="Bar"
            width="100%"
            height="400px"
            data={data}
            options={options}
        />
    );
}

