import React from "react";
import KeyboardBackspaceTwoToneIcon from "@mui/icons-material/KeyboardBackspaceTwoTone";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";



const COOKIES = () => {
  const { user } = useSelector((s) => s.auth);

  return (

    <div className="noticeLegal">
      {
        Object.keys(user).length === 0 && <div className="Back_box d-lg-flex d-none">
          <div className="icon-box">
            <Link to="/">
              <KeyboardBackspaceTwoToneIcon />
            </Link>
          </div>
          <div className="icon-box">
            <Link to="/">
              <p>Retour</p>
            </Link>
          </div>
        </div>
      }

      <div className="noticelegal_content">
        <div className="Back_box">
          <div className="icon-box">
            <Link to="/">
              {" "}
              <KeyboardBackspaceTwoToneIcon />
            </Link>
          </div>
          <div className="icon-box">
            <Link to="/">
              <p>Back to Morpheus</p>
            </Link>
          </div>
        </div>
        <div className="notice_para">
          <h2 className="f-para">COOKIES</h2>
          <span className="para_content">
            Last updated: November 19, 2023
          </span>
          <span className="para_content">
            This is the cookie policy (the "Policy") of Morpheus.ai, accessible from morpheus.ai.
            For this site to function correctly, we sometimes place small data files called
            "cookies" on your device. These cookies are downloaded to your computer to
            enhance your browsing experience and for other purposes described in this Policy
            <br />
            <br />
            As such, this Policy constitutes a legally binding agreement between you ("User",
            "Visitor", "you" or "your") and the administrator or operator of this website
            ("Administrator", "Operator", "we", "our" or "us"). This cookie policy page describes
            what cookies are, what information they collect, why and how they are used by
            Morpheus.ai and all its associated products and services (the "Services"). We also
            explain how to control and disable these cookies and how to withdraw consent for
            their collection. However, know that these actions may diminish certain aspects of
            this site's functionality.
            <br />
            <br />
            For more information on how we use, collect, and manage your data and personal
            information, please consult our privacy policy.
            <br />
            <br />
            <h3 className="para_heading">WHAT ARE COOKIES?</h3>
            <br />
            Cookies are small pieces of data stored in text files saved on your mobile device or
            computer when you visit a website. Cookies allow the website server to record and
            retain your actions and preferences, such as login information, user history, language
            settings, font settings, color settings, and other display preferences, over a given
            period. Cookies save visitors from having to re-enter their credentials or information
            each time they return to the site or navigate from one page to another.

            <br />
            <br />
            Cookies are used to remember your preferences during a single visit, through
            session cookies, or during multiple repeated visits, through persistent cookies.

          </span>
          <br />
          <br />
          <h3 className="para_heading">HOW DO WE USE COOKIES?</h3>
          <span className="para_content">
            Cookies are essential for the optimal functioning of our website and for improving its
            performance for a better user experience. The site administrator(s) control all
            cookies used on the site and authorize third parties to access them for statistics and
            analysis purposes. Cookies are necessary to identify visitors and remember their
            actions on the site and their online behaviors. We use cookies for various reasons,
            which are explained in detail below. Note that cookie-related information is not used
            to identify you personally.
            <br />
            <br />
            <h3 className="para_heading">HOW TO CONTROL COOKIES?</h3>
            You can control and/or delete cookies on your mobile device or computer and adjust
            your browser settings to prevent cookies. Users can withdraw their consent to the
            collection of cookies on the website. You can also determine whether you want to
            accept the use of cookies by the site. However, this may require you to manually
            reset your preferences each time you visit the site, and some services and features
            of the site may not function optimally.
            <br />
            <br />
            <h3 className="para_heading">DISABLING COOKIES</h3>
            You can disable the installation of cookies by adjusting the option in your browser
            settings (see your browser help for details). However, be aware that disabling
            cookies will affect the functionality of your visit and the use of this website and many
            others. Unfortunately, disabling cookies generally results in disabling certain features
            and characteristics of this site. It is recommended to enable cookies for optimal
            functionality and service of the site.
            <br />
            <br />
            <h3 className="para_heading">COOKIES WE USE</h3>
            <h4 className="para_heading_4">SESSION COOKIES</h4>
            Session cookies are temporary cookies that are operational during your visit and
            expire when you close the browser.

            <br />
            <br />
            <h4 className="para_heading_4">PERSISTENT COOKIES</h4>
            Persistent cookies store and retain your site preferences on your mobile device or
            computer, even after closing the browser or your device.
            <br />
            <br />
            <h4 className="para_heading_4">NECESSARY COOKIES</h4>
            Necessary cookies allow the website to offer a better user experience when
            navigating the website, its services, and features. These cookies let us know if you
            have created an account and logged in to access site content.
            <br />
            <br />
            <h4 className="para_heading_4">FUNCTIONALITY COOKIES</h4>
            Functionality cookies allow the website to operate optimally, following your
            preferences for your next visits. They recognize your username and customizations.
            <br />
            <br />
            <h4 className="para_heading_4">ANALYTICAL COOKIES</h4>
            Analytical cookies allow first-party and affiliated third-party services to collect and
            store aggregated data for statistical and analytical purposes on how our visitors use
            the website. While respecting visitors' privacy and personal information, these
            cookies optimize the user experience.

            <br />
            <br />
            <h4 className="para_heading_4">ACCOUNT COOKIES</h4>
            These cookies help us manage account registration and general administration.
            They may be deleted when you log out, but sometimes they remain to remember
            your preferences on the site.
            <br />
            <br />
            <h4 className="para_heading_4">LOGIN COOKIES</h4>
            We use login cookies to remember your login data and save you from logging in each
            time you visit a new page on the site. These cookies are often cleared when you log
            out to secure account information and prevent access to restricted functions.
            <br />
            <br />
            <h4 className="para_heading_4">EMAIL NEWSLETTER COOKIES</h4>
            This site uses cookies to offer subscription services to the newsletter or email and
            cookies to remember if you are already subscribed to display specific notifications or
            features reserved for subscribed or unsubscribed users.

            <br />
            <br />
            <h4 className="para_heading_4">
              ORDER PROCESSING COOKIES
            </h4>
            We use cookies to store and remember your payment orders on our e-commerce site
            or payment facilities to ensure correct payment processing.
            <br />
            <br />
            <h4 className="para_heading_4">SURVEY COOKIES</h4>
            We periodically send surveys and questionnaires to our users to provide interesting
            information, useful tools, or better understand their preferences or opinions. These
            surveys may use cookies to remember participants or generate accurate results even
            after changing pages.
            <br />
            <br />
            <h4 className="para_heading_4">FORM COOKIES</h4>
            We use cookies to remember your details for later correspondence when you submit
            data through forms such as contact or comment details.
            <br />
            <br />
            <h4 className="para_heading_4">SITE PREFERENCES COOKIES</h4>
            To offer you a beautiful user experience and interaction with the site, we use cookies
            to enable you to set your preferences when using the site.
            <br />
            <br />
            <h4 className="para_heading_4">FIRST-PARTY COOKIES</h4>
            First-party cookies are the cookies explained above that are set by the website.
            <br />
            <br />
            <h4 className="para_heading_4">THIRD-PARTY COOKIES</h4>
            In some cases, the website uses cookies provided by trusted third parties that serve
            content or provide advertising and analytics services on this website. The following
            section explains the third-party cookies you may encounter when using this site.

            <br />
            <span>
              <li>
                This site uses Google Analytics, one of the most reliable web analytics
                solutions globally, to understand how you use the site and find ways to
                improve your experience. Therefore, these cookies may track your site usage,
                the time spent on the site, and its pages to allow us to continue producing
                engaging content. For more information on Google Analytics cookies, we refer
                you to the official Google Analytics page:
                <br />
                <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">https://developers.google.com/analytics/devguides/collection/analyticsjs/co
                  okie-usage</a>
              </li>
              <li>
                We periodically test new features and make subtle changes to the interaction
                between users of our site. Cookies may be used to provide you with a
                consistent experience on the site and understand our users' preferred
                optimizations.
              </li>
              <li>
                We use cookies to track and understand the statistics regarding the number
                of visitors making purchases for the products we sell. This allows us to make
                informed business and market forecasts and control our advertising and
                product costs to ensure the best price balance.
              </li>
              <li>
                We use the Google AdSense service, which uses a DoubleClick cookie to
                serve more relevant ads across the web and limit the number of times a
                particular ad is shown to our users. For more information on Google AdSense
                cookies, we invite you to consult the official Google AdSense privacy FAQ:
                <br />
                <a href="https://support.google.com/adsense/answer/3394713">https://support.google.com/adsense/answer/3394713
                </a>
              </li>
              <li>
                We rely on advertisements to offset the operating costs of this site and
                generate revenue for its development. We use behavioral advertising cookies
                to ensure that we provide you with the most relevant ads possible by
                anonymously tracking your specific interests and presenting similar interests.

              </li>
              <li>
                Several partners advertise on our behalf, and we use affiliate tracking cookies
                to determine the number of our customers visiting the site through each
                partner's site to credit them appropriately. If applicable, these cookies allow
                our affiliate partners to offer you any bonus they might grant you for a
                purchase.
              </li>
              <li>
                We use cookies for social media buttons and/or plugins on this site to
                connect you to your social network in various ways. These cookies also allow
                us to track social media users when they visit our site or share content using a
                tagging mechanism provided by these social media sites.

              </li>
            </span>
            <br />
            <br />
            <h4 className="para_heading_4">AMENDMENTS AND MODIFICATIONS</h4>
            Morpheus.ai reserves the right to modify this policy and its terms regarding the
            website and services at any time, effective and in full force upon the posting of the
            updated version on the website. Please regularly review this page to stay informed of
            any updates. Continued use of the website and its services after this will constitute
            your consent to such amendments and modifications.
            <br />
            <br />
            <h4 className="para_heading_4">ACCEPTANCE POLICY</h4>
            You hereby acknowledge that you have read this cookie policy and accept all its
            provisions and terms and conditions. By continuing to access and use this website
            and its services, you also agree to be legally bound by this cookie policy. If you do
            not agree to be bound by the terms and conditions of this policy, you are not
            authorized to continue accessing or using this website and its services.
            <br />
            <br />
            <h4 className="para_heading_4">MORE INFORMATION</h4>
            We hope you have gained a better understanding of our use of cookies. For more
            information on our cookie policy, please send us an email at
            contact@themorpheus.ai.
            <p style={{ borderTop: " 0.5px solid rgba(255, 255, 255, 0.2)", marginTop: "30px" }}>Embrace the reality of our digital realm with understanding and clarity.</p>
          </span>

        </div>
      </div>
    </div>
  );
};

export default COOKIES;
