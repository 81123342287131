import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import KeyboardBackspaceTwoToneIcon from "@mui/icons-material/KeyboardBackspaceTwoTone";



const NOTICE_LEGALE = () => {
  const { user } = useSelector((s) => s.auth);

  return (
    <div className="noticeLegal">
      {
        Object.keys(user).length === 0 && <div className="Back_box d-lg-flex d-none">
          <div className="icon-box">
            <Link to="/">
              <KeyboardBackspaceTwoToneIcon />
            </Link>
          </div>
          <div className="icon-box">
            <Link to="/">
              <p>Retour</p>
            </Link>
          </div>
        </div>
      }
      <div className="noticelegal_content">
      <div className="Back_box">
        <div className="icon-box">
          <Link to="/">
            {" "}
            <KeyboardBackspaceTwoToneIcon />
          </Link>
        </div>
        <div className="icon-box">
          <Link to="/">
            <p>Back to Morpheus</p>
          </Link>
        </div>
      </div>  
        <div className="notice_para">
          <h5 className="legal-heading">LEGAL_NOTICE</h5>
          <span className="legal-para">Applicable since 19/11/2023</span>
          <br />
          <span className="para_content">
            In accordance with the provisions of Articles 6-III and 19 of Law No. 2004-575 of 21 June 2004 on Trust in the Digital Economy, known as L.C.E.N., users and visitors are informed, hereinafter referred to as the "User", of the website www.themorpheus.ai , hereinafter the "Website", these legal notices.
            <br /><br />
            The connection and navigation on the Wesite by the User implies full and unreserved acceptance of these legal notices. These are accessible on the Site under the heading «Legal Notice».
            <br />
            <br />
            <h3 className="para_heading">Article_1 - publisher</h3>
            The Website is edited by Morpheus.ai Auto-entreprenuer with a capital of 1 euros, registered in the Trade and Companies Register of Pontoise under the number 879518090 whose registered office is located at 10 Rue des Deux Piliers, 95350 Saint-Brice-Sous-Forêt, E-mail address: contact@themorpheus.ai. Intra-Community VAT No.: FR52879518090 The Director of the publication is Christ ITOUA hereinafter the “Publisher”.
            <br />
            <br />
            <h3 className="para_heading">Article_2 - WEB_HOST</h3>
            The host of the website is GoDaddy, whose registered office is at 2155 E. GoDaddy Way Tempe, AZ 85284 USA , with the telephone number: 330975187039.
            <br />
            <br />
            <h3 className="para_heading">Article_3 - WEBSITE_ACCESS</h3>
            Morpheus.ai is accessible anywhere, 7/7, 24/7 except in cases of force majeure, scheduled or unscheduled interruption and may arise from a need for maintenance. In case of modification, interruption or suspension of the website, the Publisher cannot be held responsible.
            <br />
            <br />
            <h3 className="para_heading">Article_4 - DATA COLLECTION</h3>
            Morpheus ensures that the User collects and processes personal information in accordance with the law n°78-17 of 6 January 1978 relating to information technology, files and freedoms.
            <br />
            Under the Data Protection Act, dated 6 January 1978, the User has the right to access, rectify, delete and oppose his personal data.
            <br />
            The User exercises this right:
            <br />
            <span className="point"><li>by email at email contact@themorpheus.ai.</li> </span>
            <br />
            Any use, reproduction, distribution, marketing, modification of all or part of the Site, without authorization from the Publisher is prohibited and may result in legal actions and proceedings as provided in particular by the Intellectual Property Code and the Civil Code.
          </span>
        </div>
      </div>
    </div>
  );
};

export default NOTICE_LEGALE;
