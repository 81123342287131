import React from "react";
import { Chart } from "react-google-charts";

export const data = [
  ["", ""],
  ["Jan", 1000],
  ["Feb", 1170],
  ["Mar", 660],
  ["Apr", 1030],
  ["May", 1030],
  ["June", 1030],
  ["July", 1030],
  ["Aug", 1030],
  ["Sep", 1030],
  ["Oct", 1030],
  ["Nov", 1030],
  ["Dec", 1030],
];

export const options = {
  chart: {
  },
};

export function Charts() {
  return (
    <Chart
      chartType="Bar"
      width="100%"
      height="400px"
      data={data}
      options={{}}
      className="chart-container"
    />
  );
}
