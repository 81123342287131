import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { GiArcheryTarget } from "react-icons/gi";
import { FaLocationArrow } from "react-icons/fa6";
import { FaCoins } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Segment } from "@mui/icons-material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TextToImageAsyncThunk, TextToImageAsyncThunkSD3 } from "../store/pagesSlices/generativeSlice";
import Matrix_top from "../Components/Matrix_top";
import { Spinner } from "react-bootstrap";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box } from "@mui/material";
import { setUser } from "../store/pagesSlices/authSlice";
import axios from 'axios';

export default function Enter_The_Matrix() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [text1, setText1] = useState("");
  const [text, setText] = useState("");
  const [style, setStyle] = useState(searchParams.get("style") || "");
  const [imgSrc, setImgSrc] = useState([]);
  console.log("🚀 ~ Enter_The_Matrix ~ imgSrc:", imgSrc)
  const [quantity, setQuantity] = useState(1);
  const [model, setModel] = useState(searchParams.get("model") || "dalle");
  const [dimensions, setDimensions] = useState({ width: 1024, height: 1024 });
  const styles = [
    { name: "Enhance", value: "enhance" },
    { name: "Anime", value: "anime" },
    { name: "Photographic", value: "photographic" },
    { name: "Digital Art", value: "digital-art" },
    { name: "Comic Book", value: "comic-book" },
    { name: "Fantasy Art", value: "fantasy-art" },
    { name: "Line Art", value: "line-art" },
    { name: "Analog Film", value: "analog-film" },
    { name: "Neon Punk", value: "neon-punk" },
    { name: "Isometric", value: "isometric" },
    { name: "Low Poly", value: "low-poly" },
    { name: "Origami", value: "origami" },
    { name: "Modeling Compound", value: "modeling-compound" },
    { name: "Cinematic", value: "cinematic" },
    { name: "3D Model", value: "3D-model" },
    { name: "Pixel Art", value: "pixel-art" },
    { name: "Tile Texture", value: "tile-texture" },
  ];
  const qty = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const allowedDimensions = [
    { width: 1024, height: 1024 },
    { width: 1152, height: 896 },
    { width: 896, height: 1152 },
    { width: 1216, height: 832 },
    { width: 832, height: 1216 },
    { width: 1344, height: 768 },
    { width: 768, height: 1344 },
    { width: 1536, height: 640 },
    { width: 640, height: 1536 },
  ];
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.imageToImage.loadings.textToImageData);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("text-image");
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    navigate(`/${selectedValue}`);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (text) {
        const headers = {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("app-access-token"))?.token}`,
        };

        let token = JSON.parse(sessionStorage.getItem("app-access-token"));

        if (model === 'sd3') {
          // Direct Axios call to your text-to-image-sd3 API
          // const response = await axios.post(
          //   `${process.env.REACT_APP_basePath}generative/text-to-image-sd3`,
          //   { prompt: text, output_format: 'jpeg' },
          //   {
          //     headers,
          //     responseType: 'arraybuffer',
          //     // Authorization = `Bearer ${token}`
          //   }
          // );

          const response = await dispatch(TextToImageAsyncThunkSD3({
            // data: { 
            // prompt: text, 
            // output_format: 'jpeg',
            text,
            model,
            quantity,
            dimensions,
            //  },
            // headers: { headers, responseType: 'arraybuffer' }
          }));


          if (response.status === 200) {
            const blob = new Blob([response.data], { type: 'image/jpeg' });
            const url = URL.createObjectURL(blob);
            setImgSrc([url]);  // Set the image source URL
            setText1(text);   // Save the prompt for display
            setText("");      // Clear the input field
          } else {
            throw new Error(`${response.status}: ${response.statusText}`);
          }
        } else {
          // Dispatch the TextToImage async thunk with the content
          const data = {
            text,
            model,
            quantity,
            dimensions,
          };
          if (style) {
            data.style = style;
          }

          const response = await dispatch(TextToImageAsyncThunk(data, headers));

          if (response.payload.data) {
            setImgSrc(response.payload.data.imagePaths);
            setText1(text);
            setText("");
            dispatch(setUser(response.payload.data.user));
          }

          console.log("Response:", response);
        }
      } else {
        console.error('Text prompt is required.');
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  let userlocal = useSelector((state) => state.auth?.user);

  return (
    <div>
      <div className="Enter_The_MATRIX">
        <Matrix_top />
        <div className='flex-box-search'>
          <div className="flex-box">
            <div className="flex-grow-1">
              <p className="d-flex align-items-center">
                <GiArcheryTarget size={16} />
                <input
                  className="search_inp"
                  type=""
                  placeholder="SEARCH"
                />
              </p>
            </div>
            <div>
              <Button className="btn-3 d-sm-flex d-none">SEARCH</Button>
            </div>
          </div>
        </div>
        <div className="Position-box">
          <div className="Button-box">
            <div className="Border-box">
              <div className="flex-box-9">
                <div>
                  <img src={"/Images/matrix.png"} className=""></img>
                </div>
                <div className="text-boxes">
                  <div className="sm-text">Finetuned Model</div>
                  <Form.Select
                    aria-label=""
                    size="sm"
                    className="lg-text"
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                  >
                    <option value={"dalle"}>Dalle</option>
                    <option value={"stable-diffusion-xl-1024-v1-0"}>
                      Stable Diffusion XL 1.0
                    </option>
                    <option value={"stable-diffusion-xl-1024-v0-9"}>
                      Stable Diffusion XL 0.9
                    </option>
                    <option value={"sd3"}>
                      Stable Diffusion 3
                    </option>
                  </Form.Select>
                </div>
              </div>
            </div>
            <div className="Border-box">
              <div>
                <div className="sm-text">Style</div>
                <Form.Select
                  aria-label=""
                  className="lg-text"
                  value={style}
                  onChange={(e) => setStyle(e.target.value)}
                >
                  <option value={""}>None</option>
                  {styles?.map((item) => (
                    <option value={item?.value} key={item?.value}>{item?.name}</option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <div className="Border-box">
              <div>
                <div className="sm-text">Image Dimensions</div>
                <Form.Select
                  onChange={(e) => setDimensions(JSON.parse(e.target.value))}
                  className="lg-text"
                  value={JSON.stringify(dimensions)}
                >
                  {allowedDimensions.map((item) => {
                    return (
                      <option value={`${item.width}x${item.height}`} key={`${item.width}x${item.height}`}>
                        {item.width} x {item.height}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>
            </div>
            {model !== 'dalle' &&
              <div className="Border-box">
                <div>
                  <div className="sm-text">Nombre d'image </div>
                  <Form.Select
                    onChange={(e) => setQuantity(e.target.value)}
                    className="lg-text"
                    value={quantity}
                  >
                    {qty.map((item) => {
                      return <option value={item} key={item}>{item} images</option>;
                    })}
                  </Form.Select>
                </div>
              </div>
            }
            <div className="Border-box">
              <div>
                <div className="sm-text">Image Input</div>
                <div className="lg-text">Img_434_</div>
              </div>
            </div>
            <div className="Border-box">
              <div className="flex-box-9 w-100">
                <div>
                  <div className="sm-text">
                    Your plan
                  </div>
                  <div className="lg-text">Awake</div>
                </div>
                <div>
                  <Link to="/annuel">
                    {" "}
                    <button>Upgrade</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="Border-box">
              <div>
                <div className="sm-text">Advanced mode</div>
                <div className="lg-text">Active</div>
              </div>
            </div>
            <div className="Border-box">
              <div>
                <div className="sm-text">Pages</div>
                <Form.Select
                  aria-label=""
                  className="lg-text"
                  onChange={handleSelectChange}
                  value={selectedOption}
                >
                  <option value="text-image">Txt to Img</option>
                  <option value="matrix-image">Img to Img</option>
                </Form.Select>
              </div>
            </div>
          </div>

          <Form className="Form_box row" onSubmit={handleSubmit}>
            <div className="col-sm-10 col-12">
              <InputGroup>
                <InputGroup.Text id="basic-addon1">
                  <AutoAwesomeIcon fontSize="10px" />{" "}
                </InputGroup.Text>
                <Form.Control
                  onChange={(e) => setText(e.target.value)}
                  value={text}
                  placeholder="TYPE A PROMPT ..."
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
            <div className="BUTTONS col-sm-2 col-12">
              <div>
                {loading ? (
                  <Spinner />
                ) : (
                  <Button className="btn-7" onClick={handleSubmit}>
                    <span className="d-sm-flex d-none">
                      <FaLocationArrow />
                    </span>
                    <span className="d-sm-none d-flex">GENERATE IMAGE</span>
                  </Button>
                )}
              </div>
              <div className="d-sm-flex d-none">
                <p>
                  {userlocal?.total_points_earned || 0} <FaCoins size={16} />
                </p>
              </div>
            </div>
          </Form>
        </div>

        <div className="flex-box-2">
          <div className="hr">
            <hr />
          </div>
          <div className="text">TODAY</div>
          <div className="hr">
            <hr />
          </div>
        </div>

        <div className="text-box">
          <p>
            <span>Prompt used </span> :  {text1 || "robot in the matrix"}
          </p>
        </div>
        <div className="img-scroll">
          <div className="row">
            {imgSrc.length !== 0 ? (
              imgSrc.map((path, index) => (
                <div className="col-sm-6" key={index}>
                  <div className="img_icon">
                    <img src={`${process.env.REACT_APP_basePath}${path}`} alt={`Generated Image ${index}`} />
                    <a
                      href={`${process.env.REACT_APP_basePath}${path}`}
                      target="_blank"
                      download={`image_${index}.jpeg`}
                    >
                      <span className="download_icon">
                        <FileDownloadIcon />
                      </span>
                    </a>
                  </div>
                </div>
              ))
            ) : (
              <>
                <div className="col-sm-6">
                  <div>
                    <img src={"/Images/Rectangle 27.jpg"} alt="Placeholder" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div>
                    <img src={"/Images/Rectangle 27.jpg"} alt="Placeholder" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div>
                    <img src={"/Images/Rectangle 27.jpg"} alt="Placeholder" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div>
                    <img src={"/Images/Rectangle 27.jpg"} alt="Placeholder" />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
